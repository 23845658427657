import {
  Button,
  CircleSubmit,
  GenericError,
  TextInput,
} from 'components/forms';
import {
  Heading,
  Paragraph,
  PageLayout,
  PageLayoutContent,
  PageLayoutFooter,
  FullPageLoading,
} from 'components';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { Account } from 'models';
import { usePatient } from 'providers/OnboardedPatientProvider';
import { assertUnreachable } from 'lib/util';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Mixpanel, MixpanelEvents } from 'helpers/mixpanel';
import { useCachedQueryParams } from 'hooks/useCachedQueryParams';
import { useNewUserOnboardingStore } from './store';
import { confirmEmployeeSponsorshipService } from './services';
import { getMembershipStatus } from './util';

const schema = z.object({
  accessCode: z.string(),
});

type Schema = z.infer<typeof schema>;

export function EmployeeSponsorshipForm() {
  const { t } = useTranslation('onboarding');
  const { accountId } = usePatient();
  const { control, formState, handleSubmit, setError } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      accessCode: undefined,
    },
  });

  const [dateOfBirth, setCurrentStep, setEmployeeSponsorship] =
    useNewUserOnboardingStore(state => [
      state.dateOfBirth,
      state.setCurrentStep,
      state.setEmployeeSponsorship,
    ]);

  if (!accountId) throw new Error('accountID is required');
  if (!dateOfBirth) throw new Error('dateOfBirth is required');

  const mutation = useMutation({
    mutationFn: (data: Schema) => {
      return confirmEmployeeSponsorshipService({
        accountId,
        accessCode: data.accessCode,
      });
    },
    onSuccess: (data: Account) => {
      const status = getMembershipStatus(data);
      Mixpanel.track(MixpanelEvents.FTUX_SUBMITTED_EMPLOYEE_SPONSORSHIP_SCREEN);
      switch (status) {
        case 'ACTIVE':
          setCurrentStep('INSURANCE_CONFIRMATION');
          break;

        case 'NO_MEMBERSHIP':
          setCurrentStep('SPONSORSHIP_REQUIRED');
          break;

        case 'NO_WEB_ACCESS':
          setCurrentStep('WEB_ACCESS_REQUIRED');
          break;

        case 'NO_PEDIATRICS':
          setCurrentStep('PEDIATRICS_INELIGIBLE');
          break;

        default:
          assertUnreachable(status);
      }
    },
    onError: () => {
      setError('root.serverError', {
        message: t('Code not recognized. Please try again.'),
      });
    },
  });

  const accessCodeValues = useCachedQueryParams([
    'accessCode',
    'accesscode',
    'access_code',
  ]);
  const accessCode =
    accessCodeValues.accessCode ||
    accessCodeValues.accesscode ||
    accessCodeValues.access_code;
  useEffect(() => {
    if (mutation.isIdle && accessCode) {
      mutation.mutate({ accessCode });
    }
  }, [mutation, accessCode]);

  const onSubmit = handleSubmit(data => {
    setEmployeeSponsorship({ employeeSponsorshipAccessCode: data.accessCode });
    return mutation.mutate(data);
  });

  const onSkip = () => {
    Mixpanel.track(MixpanelEvents.FTUX_SKIPPED_EMPLOYEE_SPONSORSHIP_SCREEN);
    setCurrentStep('WEB_ACCESS_REQUIRED');
  };

  if (mutation.isLoading) {
    return <FullPageLoading />;
  }
  return (
    <form onSubmit={onSubmit}>
      <PageLayout progress={8 / 9}>
        <PageLayoutContent>
          <Heading>
            {t('Is your organization sponsoring your Galileo membership?')}
          </Heading>
          <Paragraph type="note">
            {t(
              'Enter the access code you received from your employer, union, or other association.'
            )}
          </Paragraph>

          <GenericError message={formState.errors.root?.serverError?.message} />
          <TextInput
            control={control}
            name="accessCode"
            label={t('Access Code')}
          />

          <Paragraph>
            {t('Need help finding your access code?')} <br />
            <a href="mailto:support@galileohealth.com">{t('Contact Us')}</a>
          </Paragraph>
        </PageLayoutContent>
        <PageLayoutFooter>
          <div className="w-42 grid grid-cols-[min-content_min-content] gap-4">
            <Button type="ghost" onClick={onSkip}>
              {t('Skip')}
            </Button>
            <CircleSubmit
              valid={formState.isValid}
              loading={mutation.isLoading}
            />
          </div>
        </PageLayoutFooter>
      </PageLayout>
    </form>
  );
}
