import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Space } from 'antd';
import type { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useForm } from 'react-hook-form';
import {
  FullPageLoading,
  PageLayout,
  PageLayoutContent,
  PageLayoutFooter,
} from 'components';
import {
  CircleSubmit,
  TextInput,
  Checkbox,
  GenericError,
  InsuranceAutoComplete,
  CircleBack,
} from 'components/forms';
import { useTranslation } from 'react-i18next';
import { Mixpanel, MixpanelEvents } from 'helpers/mixpanel';
import { useMutation, useQuery } from '@tanstack/react-query';
import { usePatient } from 'providers/OnboardedPatientProvider';
import Lottie from 'lottie-react';
import loadingAnimation from 'assets/loading-animation.json';
import { InsuranceCarrier } from 'models';
import { useState } from 'react';
import { Heading, Paragraph } from '../../components/typography/Typography';
import { useNewUserOnboardingStore } from './store';
import { claimEligibilityService, getInsuranceCarriers } from './services';

const schema = z
  .object({
    insuranceProvider: z.string().default(''),
    insuranceMemberID: z.string().default(''),
    isNotInsured: z.boolean(),
  })
  .refine(data => {
    if (
      data.isNotInsured === true &&
      !data.insuranceProvider &&
      !data.insuranceMemberID
    ) {
      return true;
    }
    if (
      (data.isNotInsured === false || data.isNotInsured === undefined) &&
      data.insuranceProvider &&
      data.insuranceMemberID
    ) {
      return true;
    }
    return false;
  });

type Schema = z.infer<typeof schema>;

export function InsuranceEntryForm() {
  const { t } = useTranslation('onboarding');
  const { accountId } = usePatient();
  const {
    control,
    formState,
    handleSubmit,
    trigger,
    watch,
    setValue,
    resetField,
    setError,
  } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      insuranceProvider: '',
      insuranceMemberID: '',
      isNotInsured: false,
    },
  });

  const [isSponsored, setCurrentStep] = useNewUserOnboardingStore(state => [
    state.isSponsored,
    state.setCurrentStep,
  ]);

  const [insuranceCarriers, setInsuranceCarriers] = useState<
    InsuranceCarrier[]
  >([]);

  const checkBoxChangeHandler = (e: CheckboxChangeEvent) => {
    setValue('isNotInsured', e.target.checked);
    resetField('insuranceProvider');
    resetField('insuranceMemberID');
    trigger('isNotInsured');
  };

  const carriers = useQuery({
    queryKey: ['carriers'],
    queryFn: async () => {
      const response = await getInsuranceCarriers();
      setInsuranceCarriers(response);
      return response.reduce<SelectOptions>((result, carrier) => {
        // Filter out any bad data in the staging API
        if (carrier.group_id === null || carrier.group_id === '') return result;

        result.push({
          key: carrier.group_id,
          value: carrier.group_id,
          label: carrier.in_app_eligibility_display_name,
        });
        return result;
      }, []);
    },
  });

  const mutation = useMutation({
    mutationFn: async (data: Schema) => {
      if (data.isNotInsured) {
        if (isSponsored) {
          return { eligible: true };
        }
        return { eligible: false };
      }

      const insuranceCarrier = insuranceCarriers.find(
        carrier => carrier.group_id === data.insuranceProvider
      );
      if (insuranceCarrier?.in_app_eligibility_support === 'display_only') {
        return { eligible: false };
      }

      return claimEligibilityService({
        accountId,
        groupId: data.insuranceProvider,
        memberId: data.insuranceMemberID,
      });
    },
    onSuccess: response => {
      Mixpanel.track(MixpanelEvents.FTUX_SUBMITTED_INSURANCE_INFO_SCREEN);
      if (response.eligible || isSponsored) {
        setCurrentStep('INSURANCE_CONFIRMATION');
      } else {
        setCurrentStep('EMPLOYEE_SPONSORSHIP');
      }
    },
    onError: () => {
      setError('root.serverError', {
        message: t('Unable to verify insurance. Please try again.'),
      });
    },
  });

  const onBack = () => {
    setCurrentStep('ID_UPLOAD');
  };

  if (carriers.isLoading) return <FullPageLoading />;
  if (!carriers.data) throw new Error('Something went wrong loading carriers');

  return (
    <form onSubmit={handleSubmit(data => mutation.mutate(data))}>
      <PageLayout progress={7 / 9}>
        {mutation.isLoading && (
          <div className="absolute inset-0 z-50 grid place-items-center bg-space-10 bg-opacity-70">
            <div>
              <Lottie
                className="mx-auto mb-4 w-12"
                animationData={loadingAnimation}
                loop={false}
              />
              <p className="font-bold text-white">
                {t('Verifying your details...')}
              </p>
            </div>
          </div>
        )}

        <PageLayoutContent>
          <Heading>{t('Enter Your Insurance')}</Heading>
          <Paragraph type="note">
            {t(
              'This helps us work with in-network providers. Depending on your plan, your account may also be fully covered.'
            )}
          </Paragraph>
          <GenericError message={formState.errors.root?.serverError?.message} />
          <Space size={16} direction="vertical" className="w-full">
            <InsuranceAutoComplete
              control={control}
              name="insuranceProvider"
              options={carriers.data}
              placeholder={t('Search Insurance')}
              disabled={watch('isNotInsured')}
            />
            <TextInput
              control={control}
              name="insuranceMemberID"
              label={t('Member ID')}
              type="text"
              disabled={watch('isNotInsured')}
            />
            <Checkbox
              className="-mt-4"
              control={control}
              name="isNotInsured"
              onChange={checkBoxChangeHandler}
            >
              {t('No Insurance')}
            </Checkbox>
          </Space>
        </PageLayoutContent>
        <PageLayoutFooter>
          <CircleBack onClick={onBack} />
          <CircleSubmit
            valid={formState.isValid}
            loading={formState.isSubmitting}
          />
        </PageLayoutFooter>
      </PageLayout>
    </form>
  );
}
